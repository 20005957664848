import React from 'react'
import SinkConfig from "./SinkConfig";
import { Button, Modal,Form,Dimmer, Loader, Image, Segment } from 'semantic-ui-react'

import {v4 as uuidv4} from 'uuid';
import Config from '../Config';
import { Scene } from '@babylonjs/core';
import { SCENE } from "./Scene";
const Requestform = (props) => {
    const [open, setOpen] = React.useState(false)
    const [firstName, setFirstName] = React.useState("")
    const [lastName,setLastName] = React.useState("")
    const [company, setCompany] = React.useState("")
    const [email,setEmail] = React.useState("")
    const [checkbox, setCheckbox] = React.useState(false)
    const [comment, setComment] = React.useState("")
    const [loading, setLoading] = React.useState("disabled")
    var uuid = uuidv4()
    var firma=""
    async function sendRequest(){
        setLoading("active")
        
        
        if (company === ""){
          firma = firstName + " " + lastName
        }else{
          firma=company
        }
        console.log(firstName + " " + lastName + " " + company + " " + email + " " + checkbox + " " + comment)
        var body = JSON.stringify({"first_name": firstName, "last_name": lastName, "email": email,"firma": firma});
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: body,
          mode: 'cors',
      };

      
      

     
      let response = await fetch(Config.ServerURL + '/api/guestcustomers/', requestOptions)
      let data = await response.json()
      if (response.ok){
        
        var bodyOrder = JSON.stringify({
          "customer": data.id,
          "tischbreite": SinkConfig.tableWidth,
          "tischtiefe": 450,
          "beckenbreite": SinkConfig.sinkWidth,
          "beckentiefe": 400,
          "bohrung": SinkConfig.sinkMontage,
          "beckenposition": SinkConfig.sinkPos,
          "einzelbecken": SinkConfig.singleSink,
          "abflusstyp": SinkConfig.sinkType,
          "endpreis": SinkConfig.endpreisfloat.toFixed(2),
          "withundertable": false,
          "orderstatus": 0,
          "material": SinkConfig.materialid,
          "uuid": uuid,
          "comment": comment,
          
        });
          const requestOptionsOrder = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: bodyOrder,
            mode: 'cors',
        };
        console.log(bodyOrder)
        let responseOrder = await fetch(Config.ServerURL + "/api/orders/", requestOptionsOrder)
        setLoading("disabled")
        if(responseOrder.ok){
          alert("Ihre Anfrage wurde an unser Verkaufsteam übermittelt. Sie erhalten in Kürze eine E-Mail mit den Details zu ihrer Anfrage")
        }else{
          alert("Leider konnte Ihre Anfrage nicht gesendet werden. Bitte überprüfen Sie ihre Angaben und versuchen es erneut!")
        }

      }else{
        
      }

      /*fetch(Config.ServerURL + '/api/orders/', requestOptionsOrder)
          .then(response => {
            if (response.status != '201'){
              alert("Leider konnte Ihre Anfrage nicht gesendet werden. Bitte überprüfen Sie ihre Angaben und versuchen es erneut")
            }else{
              alert("Ihre Anfrage wurde erfolgreich übermittelt. Sie erhalten in Kürze eine E-Mail mit allen weiteren Informationen zu dem gewünschten Angebot.")
            }
          })
          .then(data => console.log(data));*/
        
        
          setOpen(false)
    }
    function handleFirstName(event) {
        setFirstName(event.target.value)
    }
    function handleLastName(event) {
        setLastName(event.target.value)
    }
    function handleCompany(event) {
        setCompany(event.target.value)
    }
    function handleEmail(event) {
        setEmail(event.target.value)
    }
    function handleComment(event){
      setComment(event.target.value)
    }
    function handleCheckbox(event) {
        setCheckbox(event.target.checked)
        console.log(event.target.checked)
    }
    return (
        <Modal
      onClose={() => {
            setOpen(false)
            
          }
        }
      onOpen={() => {
          SCENE.makeScreenshot=true
          setOpen(true)
        }
      }
      open={open}
      trigger={<Button className="ui button green">Anfrage senden</Button>}
    >
      <Modal.Header><i className="shopping cart orange icon"></i>Unverbindliches Angebot anfordern</Modal.Header>
      <Modal.Content image>
        
        <Modal.Description>
        <div>
    
  </div>
        <Form>
        <Form.Field>
        <label>Vorname *</label>
        <input placeholder='Vorname' onChange={handleFirstName} />
        </Form.Field>
        <Form.Field>
        <label>Nachname *</label>
        <input placeholder='Nachname' onChange={handleLastName} />
        </Form.Field>
        <Form.Field>
        <label>Firma (optional)</label>
        <input placeholder='Firma' onChange={handleCompany} />
        </Form.Field>
        <Form.Field>
        <label>Ihre E-Mail Adresse *</label>
        <input placeholder='E-Mail' onChange={handleEmail} />
        </Form.Field>
        <Form.Field>
          <label>Bemerkungen</label>
          <textarea rows="3" onChange={handleComment}></textarea>
        </Form.Field>
        <Form.Field>
        <div className="ui checkbox">
        <input type="checkbox" onClick={handleCheckbox} name="checkbox" id="checkbox"/>
        
        <label htmlFor="checkbox"><b>Ich bin mit der Speicherung meiner Daten zum Zwecke der Angebotserhebung und Kommunikation einverstanden.<br />
            Mir ist bekannt, dass meine Daten nach 30 Tagen gelöscht werden, falls kein Auftrag zustande kommt.</b>
        </label>
        </div>
        </Form.Field>
        
        </Form>
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={() => setOpen(false)}>
          Abbrechen
        </Button>
        
        <Button
          content="Anfrage senden"
          labelPosition='right'
          icon='checkmark'
          onClick={() => sendRequest()}
          positive
        />
        
      </Modal.Actions>
    </Modal>
    )
}
export default Requestform