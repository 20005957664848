import React from "react";
import "@babylonjs/loaders";
import * as GUI from "@babylonjs/gui";
import "@babylonjs/inspector";
import { SCENE } from "./Scene";
import SinkConfig from "./SinkConfig";
import * as BABYLON from "@babylonjs/core";
import Config from "../Config";


import {
  Vector3,
  SceneLoader,
  ArcRotateCamera,
  Camera,
  DefaultRenderingPipeline,
  DefaultLoadingScreen,
  Scene,
} from "@babylonjs/core";

import SceneComponent from "./SceneComponent"; // uses above component in same directory
//import SceneComponent from "babylonjs-hook"; // if you install 'babylonjs-hook' NPM.

//Glossyfactor
const glossfactor = 0.1


//import { useState } from "react";

const Canvas = (props) => {
 //var layer = new BABYLON.Layer('','', Scene, true);
 
 
 var posLineCenter = new GUI.Line();
 var posLineLeft = new GUI.Line();
 var posLineRight = new GUI.Line();
 var posLineCentertext = new GUI.TextBlock()
 var sinkWidthLineCenter = new GUI.Line();
 var sinkWidthLineLeft = new GUI.Line();
 var sinkWidthLineRight = new GUI.Line();
 var tischWidthLineCenter = new GUI.Line();
 var tischWidthLineLeft = new GUI.Line();
 var tischWidthLineRight = new GUI.Line();
  var newSink = null;
  DefaultLoadingScreen.prototype.displayLoadingUI = function () {
    if (document.getElementById("customLoadingScreenDiv")) {
      // Do not add a loading screen if there is already one
      //document.getElementById("customLoadingScreenDiv").style.display = "initial";
      return;
    }
  };

  //Scene Loading Progress
  function sceneLoadProgress(event, scene) {
    let percentage = (event.loaded / event.total) * 100;
    console.log(percentage + "% loaded");
    // replace the default loading screen

    // show the loading screen
  }

  //Setup the scene parameters and variables
  function setupScene(scene) {
    //var Becken = scene.getMeshByID("Becken");
    var engine = scene.getEngine();
    // show the loading screen
    engine.hideLoadingUI();
    
    var camera = scene.cameras[1];
    camera.wheelPrecision = 80;
    camera.minZ = 0.1;
    camera.fovMode = Camera.FOVMODE_VERTICAL_FIXED;
    camera.upperBetaLimit = 1.512;
    camera.lowerBetaLimit = 0.7;
    //camera.upperAlphaLimit = 5.68
    //camera.lowerAlphaLimit = 3.650
    camera.lowerRadiusLimit = 1;
    camera.upperRadiusLimit = 4;
    camera.ellipsoid = new Vector3(1, 1, 1);
    camera.checkCollisions = true;
    scene.switchActiveCamera(camera);
    camera.attachControl(scene.getEngine().getRenderingCanvas(), true);

    var pipeline = new DefaultRenderingPipeline(
      "defaultPipeline", // The name of the pipeline
      true, // Do you want the pipeline to use HDR texture?
      scene, // The scene instance
      [scene.cameras[1], scene.getCameraByID("screenshotcam")] // The list of cameras to be attached to
    );
    pipeline.glowLayerEnabled = false;

    //pipeline.glowLayer.intensity = 0.5;
    //pipeline.glowLayer.blurKernelSize = 50;
    pipeline.imageProcessing.contrast = 1.2;
    pipeline.imageProcessing.exposure = 0.9;

    pipeline.imageProcessing.toneMappingEnabled = false;
    pipeline.imageProcessing.toneMappingType = 1;
    pipeline.imageProcessing.vignetteEnabled = true;
    pipeline.imageProcessing.vignetteWeight = 1.5;
    pipeline.fxaaEnabled = true;
    pipeline.sharpenEnabled = false;

    scene.getMeshByID("booleancube_left").isVisible = false;
    scene.getMeshByID("booleancube_right").isVisible = false;
    scene.getMeshByID("booleancube").isVisible = false;
    scene.getMeshByID("Punkt_left").isVisible = false;
    scene.getMeshByID("Punkt_right").isVisible = false;
    scene.getMeshByID("Punkt").isVisible = false;
    scene.getMeshByID("Rinne_left").isVisible = false;
    scene.getMeshByID("Rinne_right").isVisible = false;
    scene.getMeshByID("Rinne").isVisible = false;
    scene.getMeshByID("Schlitz_left").isVisible = false;
    scene.getMeshByID("Schlitz_right").isVisible = false;
    scene.getMeshByID("Schlitz").isVisible = false;
    scene.getMeshByID("Becken").isVisible = false;
    scene.getMeshByID("Doppelbecken").isVisible = false;
    let cubemat = scene.getMaterialByID("cubemat")
    cubemat.metallicF0Factor = glossfactor
    cubemat.roughness = 0
    let faucetSinkRight = scene.getMeshByID("f_sink").clone("right")
    let faucetWallRight = scene.getMeshByID("f_wall").clone("right")
    var hl = new BABYLON.HighlightLayer("hl1", scene);
    hl.blurHorizontalSize = 0.5
    hl.blurVerticalSize = 0.5
    
    hl.addMesh(scene.getMeshByID("ablage"), BABYLON.Color3.FromHexString("#ff8000"));
    var advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI",true,scene)
    advancedTexture.addControl(posLineCenter)
    advancedTexture.addControl(posLineLeft)
    advancedTexture.addControl(posLineRight)
    advancedTexture.addControl(posLineCentertext)
    advancedTexture.addControl(sinkWidthLineCenter)
    advancedTexture.addControl(sinkWidthLineLeft)
    advancedTexture.addControl(sinkWidthLineRight)
    advancedTexture.addControl(tischWidthLineCenter)
    advancedTexture.addControl(tischWidthLineLeft)
    advancedTexture.addControl(tischWidthLineRight)
    
    doBool(scene);
    SCENE.alwaysUpdate=true
    // Show inspector.
    /*  scene.debugLayer.show({
      embedMode: false,
    });  */
  }
  

  const onSceneReady = (scene) => {
    // replace the default loading screen

    SceneLoader.Append(
      "/scenes/",
      "background_scene_babylon.babylon",
      scene,
      (scene) => setupScene(scene),
      (ev, scene) => sceneLoadProgress(ev, scene)
    );
    // This creates and positions a free camera (non-mesh)
    //var camera = new FreeCamera("cameramain", new Vector3(0, 5, -10), scene);

    // This targets the camera to scene origin
    //camera.setTarget(Vector3.Zero());

    const canvas = scene.getEngine().getRenderingCanvas();

    // This attaches the camera to the canvas
    //camera.attachControl(canvas, true);
    var camera = new ArcRotateCamera(
      "Camera",
      Math.PI / 2,
      Math.PI / 2,
      2,
      new Vector3(0, 0, -5),
      scene
    );
    camera.attachControl(canvas, true);
    
    // This creates a light, aiming 0,1,0 - to the sky (non-mesh)
    //var light = new HemisphericLight("light", new Vector3(0, 1, 0), scene);

    // Default intensity is 1. Let's dim the light a small amount
    //light.intensity = 0.7;

    // Our built-in 'box' shape.
    //box = MeshBuilder.CreateBox("box", { size:.5 }, scene);

    // Move the box upward 1/2 its height
    //box.position.y = 1;

    // Our built-in 'ground' shape.
    //MeshBuilder.CreateGround("ground", { width: 6, height: 6 }, scene);
    console.log(scene)
  };

  /**
   * Updates the scene to reflect the current configuration.
   * Will only execute if SCENE.update = true
   */
  function updateScene(scene) {
    var ablage = scene.getMeshByID("ablage");
    var mirror = scene.getMeshByID("Spiegel");
    var wand = scene.getMeshByID("wand");
    var Becken = scene.getMeshByID("csg");
    var Doppelbecken = scene.getMeshByID("Doppelbecken");
    var Punkt = scene.getMeshByID("Punkt");
    var PunktLeft = scene.getMeshByID("Punkt_left");
    var PunktRight = scene.getMeshByID("Punkt_right");
    var Schlitz = scene.getMeshByID("Schlitz");
    var SchlitzLeft = scene.getMeshByID("Schlitz_left");
    var SchlitzRight = scene.getMeshByID("Schlitz_right");
    var Rinne = scene.getMeshByID("Rinne");
    var RinneLeft = scene.getMeshByID("Rinne_left");
    var RinneRight = scene.getMeshByID("Rinne_right");
    var faucetWall = scene.getMeshByID("f_wall");
    var faucetSink = scene.getMeshByID("f_sink");
    var Soap = scene.getMeshByID("Soap")
    var faucetSinkRight = scene.getMeshByID("right.f_sink");
    var faucetWallRight = scene.getMeshByID("right.f_wall");
    
    var booleancube = scene.getMeshByID("booleancube")
    var booleancubeRight = scene.getMeshByID("booleancube_right")
    var booleancubeLeft = scene.getMeshByID("booleancube_left")
    
    //scale Sink

    Punkt.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    Rinne.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    Schlitz.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    PunktLeft.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    RinneLeft.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    SchlitzLeft.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    PunktRight.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    RinneRight.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    SchlitzRight.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    scene.getMeshByID("Becken").scaling.x = SinkConfig.tableWidth * SinkConfig.scale;
    booleancube.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    booleancubeRight.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    booleancubeLeft.scaling.x = SinkConfig.sinkWidth * SinkConfig.scale;
    
    
    if (SinkConfig.singleSink) {
      //switch sinkMontage

      if (SinkConfig.sinkMontage) {
        faucetWall.isVisible = false;
        faucetWallRight.isVisible = false;
        faucetSink.isVisible = true;
        faucetSinkRight.isVisible = false;
      } else {
        faucetWall.isVisible = true;
        faucetSink.isVisible = false;
        faucetWallRight.isVisible = false;
        faucetSinkRight.isVisible = false;
      }
      faucetSink.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      faucetWall.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      booleancube.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      Punkt.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      Rinne.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      Schlitz.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      PunktRight.isVisible=false;
      PunktLeft.isVisible=false;
      SchlitzRight.isVisible=false;
      SchlitzLeft.isVisible=false;
      RinneRight.isVisible=false;
      RinneLeft.isVisible=false;
      Punkt.isVisible=true;
      Schlitz.isVisible=true;
      Rinne.isVisible=true;

    }else{
      //switch sinkMontage
      if (SinkConfig.sinkMontage) {
        faucetWall.isVisible = false;
        faucetWallRight.isVisible = false;
        faucetSink.isVisible = true;
        faucetSinkRight.isVisible = true;
        

      } else {
        faucetWall.isVisible = true;
        faucetSink.isVisible = false;
        faucetWallRight.isVisible = true;
        faucetSinkRight.isVisible = false;
      }
      faucetSink.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      faucetWall.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      faucetSinkRight.position.x = (SinkConfig.tableWidth/2 * SinkConfig.scale) - (SinkConfig.sinkPos * SinkConfig.scale);
      faucetWallRight.position.x = (SinkConfig.tableWidth/2 * SinkConfig.scale) - (SinkConfig.sinkPos * SinkConfig.scale);
      booleancubeLeft.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      booleancubeRight.position.x = (SinkConfig.tableWidth/2 * SinkConfig.scale) - (SinkConfig.sinkPos * SinkConfig.scale);
      Punkt.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      Rinne.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      Schlitz.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      PunktRight.position.x = (SinkConfig.tableWidth/2 * SinkConfig.scale) - (SinkConfig.sinkPos * SinkConfig.scale);
      RinneRight.position.x = (SinkConfig.tableWidth/2 * SinkConfig.scale) - (SinkConfig.sinkPos * SinkConfig.scale);
      SchlitzRight.position.x = (SinkConfig.tableWidth/2 * SinkConfig.scale) - (SinkConfig.sinkPos * SinkConfig.scale);
      PunktLeft.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      RinneLeft.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      SchlitzLeft.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale);
      PunktRight.isVisible=true;
      PunktLeft.isVisible=true;
      SchlitzRight.isVisible=true;
      SchlitzLeft.isVisible=true;
      RinneRight.isVisible=true;
      RinneLeft.isVisible=true;
      Punkt.isVisible=false;
      Schlitz.isVisible=false;
      Rinne.isVisible=false;

      
    }
    Soap.position.x = -(SinkConfig.tableWidth/2 * SinkConfig.scale) + (SinkConfig.sinkPos * SinkConfig.scale) + (SinkConfig.sinkWidth/2 * SinkConfig.scale) + (40 * SinkConfig.scale);
    ablage.scaling.x = SinkConfig.tableWidth * SinkConfig.scale;

    //scale boolean

    //Show undertable
    if (SCENE.showUnderTable) {
      ablage.material.alpha = 1;
      ablage.material.albedoTexture = Becken.material.subMaterials[0].albedoTexture
      scene.effectLayers[0].isEnabled=false
      
    } else {
      ablage.material.alpha = 0.3;
      scene.effectLayers[0].isEnabled=true
      let at = new BABYLON.Texture("scenes/wand.jpg", scene)
      ablage.material.albedoTexture = at
    }

    //switch Scenetype
    if (!SCENE.viewType) {
      mirror.isVisible = true;
      wand.scaling.y = 0.25;
    } else {
      mirror.isVisible = false;
      wand.scaling.y = 0.12;
    }
    SCENE.update = false;


    

    //switch Sinktype
    if (SinkConfig.sinkType === 0) {
      if (SinkConfig.singleSink) {
        Punkt.isVisible = true;
        PunktLeft.isVisible = false;
        PunktRight.isVisible = false;
        Schlitz.isVisible = false;
        SchlitzLeft.isVisible = false;
        SchlitzRight.isVisible = false;
        Rinne.isVisible = false;
        RinneLeft.isVisible = false;
        RinneRight.isVisible = false;
      } else {
        Punkt.isVisible = false;
        PunktLeft.isVisible = true;
        PunktRight.isVisible = true;
        Schlitz.isVisible = false;
        SchlitzLeft.isVisible = false;
        SchlitzRight.isVisible = false;
        Rinne.isVisible = false;
        RinneLeft.isVisible = false;
        RinneRight.isVisible = false;
      }
    } else if (SinkConfig.sinkType === 1) {
      if (SinkConfig.singleSink) {
        Punkt.isVisible = false;
        PunktLeft.isVisible = false;
        PunktRight.isVisible = false;
        Schlitz.isVisible = false;
        SchlitzLeft.isVisible = false;
        SchlitzRight.isVisible = false;
        Rinne.isVisible = true;
        RinneLeft.isVisible = false;
        RinneRight.isVisible = false;
      } else {
        Punkt.isVisible = false;
        PunktLeft.isVisible = false;
        PunktRight.isVisible = false;
        Schlitz.isVisible = false;
        SchlitzLeft.isVisible = false;
        SchlitzRight.isVisible = false;
        Rinne.isVisible = false;
        RinneLeft.isVisible = true;
        RinneRight.isVisible = true;
      }
    } else {
      if (SinkConfig.singleSink) {
        Punkt.isVisible = false;
        PunktLeft.isVisible = false;
        PunktRight.isVisible = false;
        Schlitz.isVisible = true;
        SchlitzLeft.isVisible = false;
        SchlitzRight.isVisible = false;
        Rinne.isVisible = false;
        RinneLeft.isVisible = false;
        RinneRight.isVisible = false;
      } else {
        Punkt.isVisible = false;
        PunktLeft.isVisible = false;
        PunktRight.isVisible = false;
        Schlitz.isVisible = false;
        SchlitzLeft.isVisible = true;
        SchlitzRight.isVisible = true;
        Rinne.isVisible = false;
        RinneLeft.isVisible = false;
        RinneRight.isVisible = false;
      }
    }

    //Update Material
    var tex = new BABYLON.Texture(SinkConfig.materialimage, scene)
    Becken.material.subMaterials[0].albedoTexture = tex
    Doppelbecken.material.subMaterials[0].albedoTexture = tex
    Rinne.material.albedoTexture = tex
    ablage.material.albedoTexture=tex
    
    if(!SCENE.viewType){
      wand.material.albedoTexture=tex
      if(SinkConfig.materialglossy){
        wand.material.metallicF0Factor=glossfactor
        wand.material.roughness=0
      }else{
        wand.material.metallicF0Factor=0
        wand.material.roughness=1
      }
    }else{
      let t = new BABYLON.Texture("scenes/wand.jpg")
      wand.material.albedoTexture = t
      wand.material.metallicF0Factor=0
      wand.material.roughness=1
    }
    if(SinkConfig.materialglossy){
      Becken.material.subMaterials[0].metallicF0Factor = glossfactor
      Becken.material.subMaterials[0].roughness = 0
      Doppelbecken.material.subMaterials[0].metallicF0Factor = glossfactor
      Doppelbecken.material.subMaterials[0].roughness = 0
      Rinne.material.metallicF0Factor = glossfactor
      Rinne.material.roughness = 0
      ablage.material.metallicF0Factor = glossfactor
      ablage.material.roughness = 0
    }else{
      Becken.material.subMaterials[0].metallicF0Factor = 0
      Becken.material.subMaterials[0].roughness = 1
      Doppelbecken.material.subMaterials[0].metallicF0Factor = 0
      Doppelbecken.material.subMaterials[0].roughness = 1
      Rinne.material.metallicF0Factor = 0
      Rinne.material.roughness = 1
      ablage.material.metallicF0Factor = 0
      ablage.material.roughness = 1
    }

    
    calcPrice()    
    doBool(scene);
    if(SCENE.showSinkPositionLines){
      drawSinkPos(true,scene)
    }else{
      drawSinkPos(false,scene)
    }
    if(SCENE.showTableWidthLines){
      drawTableWidth(true,scene)
    }else{
      drawTableWidth(false,scene)
    }
    if(SCENE.showSinkWidthLines){
      drawSinkWidth(true,scene)
    }else{
      drawSinkWidth(false,scene)
    }
    
    if(SCENE.makeScreenshot){
      SCENE.makeScreenshot = false
      BABYLON.Tools.CreateScreenshotUsingRenderTarget(scene.getEngine(), scene.getCameraByID("screenshotcam"), {width:600, height:225}, function(data){props.setScreenshot(data)},"image/jpg");
    }
  }

  function project2D(pos,scene) {
    let engine = scene.getEngine()
    let newpos = BABYLON.Vector3.Project(pos,
      BABYLON.Matrix.Identity(),
      scene.getTransformMatrix(),
      
      scene.activeCamera.viewport.toGlobal(engine.getRenderWidth(true), engine.getRenderHeight(true)));
  
    return newpos
  }

  function drawSinkPos(show,scene) {
    let tisch = scene.getMeshByID("Becken")
    var booleanCube = scene.getMeshByID("booleancube")
    if(!SinkConfig.singleSink){
      booleanCube = scene.getMeshByID("booleancube_left")
    }
    if (show) {
      //console.log(scene)
      posLineLeft.isVisible = true
      posLineCenter.isVisible = true
      posLineRight.isVisible = true
      let offsetY = 0
      let offsetZ = .05
      // dashed line
      let posBooleanCube = new BABYLON.Vector3(
        tisch.position.x - (SinkConfig.tableWidth /2 * SinkConfig.scale),
        offsetY + tisch.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        tisch.position.z - (SinkConfig.beckenTiefe * SinkConfig.scale)
      )
      let posTisch = new BABYLON.Vector3(
        booleanCube.position.x ,
        offsetY + booleanCube.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        booleanCube.position.z - (SinkConfig.beckenTiefe * SinkConfig.scale)
      )
      let a = project2D(posBooleanCube,scene)
      let b = project2D(posTisch,scene)
      // left line
      let posTischLeft = new BABYLON.Vector3(
        tisch.position.x - ((SinkConfig.tableWidth/2) * SinkConfig.scale),
        offsetY + tisch.position.y + ((SinkConfig.beckenHoehe * SinkConfig.scale)),
        tisch.position.z + (SinkConfig.tableDepth * SinkConfig.scale)
      )
      let posTischFront = new BABYLON.Vector3(
        tisch.position.x - ((SinkConfig.tableWidth/2) * SinkConfig.scale),
        offsetY + tisch.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        tisch.position.z - (SinkConfig.tableDepth * SinkConfig.scale) - offsetZ)
      let at = project2D(posTischLeft,scene)
      let bt = project2D(posTischFront,scene)
      //right line
      let posBooleanCubeRight = new BABYLON.Vector3(
        booleanCube.position.x,
        offsetY + tisch.position.y + ((SinkConfig.beckenHoehe * SinkConfig.scale)),
        tisch.position.z + (SinkConfig.tableDepth * SinkConfig.scale)
      )
  
      let posBooleanCubeFront = new BABYLON.Vector3(
        booleanCube.position.x,
        offsetY + tisch.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        tisch.position.z - (SinkConfig.tableDepth * SinkConfig.scale) - offsetZ)
  
      let as = project2D(posBooleanCubeRight,scene)
      let bs = project2D(posBooleanCubeFront,scene)
  
  
      let lineColor = "orange"
      let lineWidth = 3
  
      posLineLeft.x1 = at.x
      posLineLeft.y1 = at.y
      posLineLeft.x2 = bt.x
      posLineLeft.y2 = bt.y
      posLineLeft.lineWidth = lineWidth;
      posLineLeft.color = lineColor
  
      posLineRight.x1 = as.x
      posLineRight.y1 = as.y
      posLineRight.x2 = bs.x
      posLineRight.y2 = bs.y
      posLineRight.lineWidth = lineWidth;
      posLineRight.color = lineColor
  
  
      posLineCenter.x1 = a.x;
      posLineCenter.y1 = a.y;
      posLineCenter.x2 = b.x;
      posLineCenter.y2 = b.y;
      posLineCenter.lineWidth = lineWidth * 1.5;
      posLineCenter.dash = [15, 5, 5, 5];
      posLineCenter.color = lineColor;
  
  
      posLineCentertext.color = lineColor
      posLineCentertext.x = a.x
      posLineCentertext.y = a.y
      posLineCentertext.text = ""
    } else {
      posLineLeft.isVisible = false
      posLineCenter.isVisible = false
      posLineRight.isVisible = false
    }
  
  
  
  }
  
  
  function drawSinkWidth(show,scene) {
    
    let booleanCube = scene.getMeshByID("booleancube")
    if(!SinkConfig.singleSink){
      booleanCube = scene.getMeshByID("booleancube_left")
    }
    if (show) {
      sinkWidthLineLeft.isVisible = true
      sinkWidthLineCenter.isVisible = true
      sinkWidthLineRight.isVisible = true
      let offsetY = 0
      let offsetZ = .05
      // dashed line
      let posBooleanCubeFrontLeft = new BABYLON.Vector3(
        booleanCube.position.x - (SinkConfig.sinkWidth /2 * SinkConfig.scale),
        offsetY + booleanCube.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        booleanCube.position.z - (SinkConfig.beckenTiefe * SinkConfig.scale)
      )
      let posBooleanCubeFrontRight = new BABYLON.Vector3(
        booleanCube.position.x + ((SinkConfig.sinkWidth /2) * SinkConfig.scale),
        offsetY + booleanCube.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        booleanCube.position.z - (SinkConfig.beckenTiefe * SinkConfig.scale)
      )
      let a = project2D(posBooleanCubeFrontLeft,scene)
      let b = project2D(posBooleanCubeFrontRight,scene)
  
      // left line
      let posTischLeft = new BABYLON.Vector3(
        booleanCube.position.x - ((SinkConfig.sinkWidth /2) * SinkConfig.scale),
        offsetY + booleanCube.position.y + ((SinkConfig.beckenHoehe * SinkConfig.scale)),
        booleanCube.position.z + (SinkConfig.beckenTiefe * SinkConfig.scale)
      )
      let posTischFront = new BABYLON.Vector3(
        booleanCube.position.x - ((SinkConfig.sinkWidth /2) * SinkConfig.scale),
        offsetY + booleanCube.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        booleanCube.position.z - (SinkConfig.beckenTiefe * SinkConfig.scale) - offsetZ)
      let at = project2D(posTischLeft,scene)
      let bt = project2D(posTischFront,scene)
  
      //right line
      let posBooleanCubeRight = new BABYLON.Vector3(
        booleanCube.position.x + (SinkConfig.sinkWidth /2 * SinkConfig.scale),
        offsetY + booleanCube.position.y + ((SinkConfig.beckenHoehe * SinkConfig.scale)),
        booleanCube.position.z + (SinkConfig.beckenTiefe * SinkConfig.scale)
      )
      let posBooleanCubeFront = new BABYLON.Vector3(
        booleanCube.position.x + (SinkConfig.sinkWidth /2 * SinkConfig.scale),
        offsetY + booleanCube.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        booleanCube.position.z - (SinkConfig.beckenTiefe * SinkConfig.scale) - offsetZ)
  
      let as = project2D(posBooleanCubeRight,scene)
      let bs = project2D(posBooleanCubeFront,scene)
  
  
      let lineColor = "orange"
      let lineWidth = 3
  
      sinkWidthLineLeft.x1 = at.x
      sinkWidthLineLeft.y1 = at.y
      sinkWidthLineLeft.x2 = bt.x
      sinkWidthLineLeft.y2 = bt.y
      sinkWidthLineLeft.lineWidth = lineWidth;
      sinkWidthLineLeft.color = lineColor
  
      sinkWidthLineRight.x1 = as.x
      sinkWidthLineRight.y1 = as.y
      sinkWidthLineRight.x2 = bs.x
      sinkWidthLineRight.y2 = bs.y
      sinkWidthLineRight.lineWidth = lineWidth;
      sinkWidthLineRight.color = lineColor
  
  
      sinkWidthLineCenter.x1 = a.x;
      sinkWidthLineCenter.y1 = a.y;
      sinkWidthLineCenter.x2 = b.x;
      sinkWidthLineCenter.y2 = b.y;
      sinkWidthLineCenter.lineWidth = lineWidth * 1.5;
      sinkWidthLineCenter.dash = [15, 5, 5, 5];
      sinkWidthLineCenter.color = lineColor;
  
  
      posLineCentertext.color = lineColor
      posLineCentertext.x = a.x
      posLineCentertext.y = a.y
      posLineCentertext.text = ""
    } else {
      sinkWidthLineLeft.isVisible = false
      sinkWidthLineCenter.isVisible = false
      sinkWidthLineRight.isVisible = false
    }
  
  
  }
  
  function drawTableWidth(show,scene) {
    let tisch = scene.getMeshByID("Becken")
    
    if (show) {
      tischWidthLineLeft.isVisible = true
      tischWidthLineCenter.isVisible = true
      tischWidthLineRight.isVisible = true
      let offsetY = 0
      let offsetZ = .05
      // dashed line
      let posBooleanCubeFrontLeft = new BABYLON.Vector3(
        tisch.position.x - (SinkConfig.tableWidth/2 * SinkConfig.scale),
        offsetY + tisch.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        tisch.position.z - (SinkConfig.tableDepth * SinkConfig.scale)
      )
      let posBooleanCubeFrontRight = new BABYLON.Vector3(
        tisch.position.x + ((SinkConfig.tableWidth/2) * SinkConfig.scale),
        offsetY + tisch.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        tisch.position.z - (SinkConfig.tableDepth * SinkConfig.scale)
      )
      let a = project2D(posBooleanCubeFrontLeft,scene)
      let b = project2D(posBooleanCubeFrontRight,scene)
  
      // left line
      let posTischLeft = new BABYLON.Vector3(
        tisch.position.x - ((SinkConfig.tableWidth/2) * SinkConfig.scale),
        offsetY + tisch.position.y + ((SinkConfig.beckenHoehe * SinkConfig.scale)),
        tisch.position.z + (SinkConfig.tableDepth * SinkConfig.scale)
      )
      let posTischFront = new BABYLON.Vector3(
        tisch.position.x - ((SinkConfig.tableWidth/2) * SinkConfig.scale),
        offsetY + tisch.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        tisch.position.z - (SinkConfig.tableDepth * SinkConfig.scale) - offsetZ)
      let at = project2D(posTischLeft,scene)
      let bt = project2D(posTischFront,scene)
  
      //right line
      let posBooleanCubeRight = new BABYLON.Vector3(
        tisch.position.x + (SinkConfig.tableWidth/2 * SinkConfig.scale),
        offsetY + tisch.position.y + ((SinkConfig.beckenHoehe * SinkConfig.scale)),
        tisch.position.z + (SinkConfig.tableDepth * SinkConfig.scale)
      )
      let posBooleanCubeFront = new BABYLON.Vector3(
        tisch.position.x + (SinkConfig.tableWidth/2 * SinkConfig.scale),
        offsetY + tisch.position.y + (SinkConfig.beckenHoehe * SinkConfig.scale),
        tisch.position.z - (SinkConfig.tableDepth * SinkConfig.scale) - offsetZ)
  
      let as = project2D(posBooleanCubeRight,scene)
      let bs = project2D(posBooleanCubeFront,scene)
  
  
      let lineColor = "orange"
      let lineWidth = 3
  
      tischWidthLineLeft.x1 = at.x
      tischWidthLineLeft.y1 = at.y
      tischWidthLineLeft.x2 = bt.x
      tischWidthLineLeft.y2 = bt.y
      tischWidthLineLeft.lineWidth = lineWidth;
      tischWidthLineLeft.color = lineColor
  
      tischWidthLineRight.x1 = as.x
      tischWidthLineRight.y1 = as.y
      tischWidthLineRight.x2 = bs.x
      tischWidthLineRight.y2 = bs.y
      tischWidthLineRight.lineWidth = lineWidth;
      tischWidthLineRight.color = lineColor
  
      //    console.log("TischLineCenter: " +posBooleanCubeFrontLeft)
      tischWidthLineCenter.x1 = a.x;
      tischWidthLineCenter.y1 = a.y;
      tischWidthLineCenter.x2 = b.x;
      tischWidthLineCenter.y2 = b.y;
      tischWidthLineCenter.lineWidth = lineWidth * 1.5;
      tischWidthLineCenter.dash = [15, 5, 5, 5];
      tischWidthLineCenter.color = lineColor;
  
  
      posLineCentertext.color = lineColor
      posLineCentertext.x = a.x
      posLineCentertext.y = a.y
      posLineCentertext.text = ""
    } else {
      tischWidthLineLeft.isVisible = false
      tischWidthLineCenter.isVisible = false
      tischWidthLineRight.isVisible = false
    }
  
  
  }
  function calcPrice() {
    var auflagen = 0
    var ablagenpreis = SinkConfig.ablagenpreis
    let bp = 0.0
    if (SinkConfig.tableWidth < 1300) {
      auflagen = SinkConfig.auflagenpreis * 2
      SinkConfig.anzahlauflagen = 2
    } else {
      auflagen = SinkConfig.auflagenpreis * 3
      SinkConfig.anzahlauflagen = 3
    }

    let calcLength = Math.ceil(SinkConfig.tableWidth/10)
    if (SCENE.showUnderTable){
      if(SinkConfig.tableWidth >= 1000){
        bp=480 + (4*(calcLength - 100))
        ablagenpreis = (bp/100*SinkConfig.dealerMargin) + bp
      }else{
        bp = 480 - (3*(100-calcLength ))
        ablagenpreis = (bp/100*SinkConfig.dealerMargin) + bp
      }
    }
    SinkConfig.ablagenpreis = ablagenpreis
    //console.log("Ablagenpreis: " + SinkConfig.ablagenpreis)
    var mwst = Config.MwStNum
    
    if (SinkConfig.tableWidth > SinkConfig.minTableWidth) {
      //console.log(Math.ceil((SinkConfig.tableWidth-SinkConfig.tischMinBreite)/10)*10*.75)
  
      var p = SinkConfig.baseprice + (Math.ceil((SinkConfig.tableWidth - SinkConfig.minTableWidth) / 10) * 10 * .75)
      if (SinkConfig.singleSink == false) {
        p += SinkConfig.doppelaufschlag
  
      }
      p += auflagen

      SinkConfig.endpreis = ((p + (p * SinkConfig.aufschlag)))
      if(SCENE.showUnderTable){
        SinkConfig.basendpreisfloat = SinkConfig.endpreis + (SinkConfig.endpreis/100 * mwst)
        SinkConfig.basendpreis = parseFloat(SinkConfig.baseendpreisfloat.toFixed(2)).toLocaleString()
        SinkConfig.endpreis += SinkConfig.ablagenpreis
        SinkConfig.endpreis = SinkConfig.endpreis + (SinkConfig.endpreis / 100 * mwst)
        SinkConfig.endpreisfloat = SinkConfig.endpreis
        SinkConfig.endpreis = parseFloat(SinkConfig.endpreis.toFixed(2)).toLocaleString() 
      }else{
        SinkConfig.endpreis = SinkConfig.endpreis + (SinkConfig.endpreis / 100 * mwst)
        SinkConfig.endpreisfloat = SinkConfig.endpreis
        SinkConfig.endpreis = parseFloat(SinkConfig.endpreis.toFixed(2)).toLocaleString() 
        SinkConfig.basendpreis = SinkConfig.endpreis
        SinkConfig.baseendpreisfloat = SinkConfig.endpreisfloat
      }
      
  
    } else {
      if (SinkConfig.singleSink === false) {
        p += SinkConfig.doppelaufschlag
      } else {
        p = 0
      }
      let basis = ((SinkConfig.baseprice + auflagen + p) + ((SinkConfig.baseprice + auflagen + p) * SinkConfig.aufschlag))
      if(SCENE.showUnderTable){
        basis += SinkConfig.ablagenpreis
        let endpreis = basis + (basis / 100 * mwst)
        SinkConfig.endpreisfloat = endpreis
        SinkConfig.endpreis = parseFloat(endpreis.toFixed(2)).toLocaleString() 
      }else {
        let endpreis = basis + (basis / 100 * mwst)
        SinkConfig.endpreisfloat = endpreis
        SinkConfig.endpreis = parseFloat(endpreis.toFixed(2)).toLocaleString() 
        SinkConfig.baseendpreisfloat = SinkConfig.endpreisfloat
        SinkConfig.basendpreis = SinkConfig.endpreis
      }
      

      //preis.innerHTML = parseFloat(endpreis.toFixed(2)).toLocaleString() + ",- €"
    
      //SinkConfig.endpreis = parseFloat(endpreis.toFixed(2)).toLocaleString()
      
      
      //console.log(SinkConfig.endpreis)
    }
    props.setEndprice(SinkConfig.endpreisfloat.toFixed(2))
    
  }
  /**
   * doBool(scene)
   * Does the boolean calculations on the sinktable
   */
  function doBool(scene) {
    
    var bBecken = scene.getMeshByID("Becken");
    var bDoppelbecken = scene.getMeshByID("Doppelbecken");
    var bbooleancubeLeft = scene.getMeshByID("booleancube_left");
    var bbooleancubeRight = scene.getMeshByID("booleancube_right");
    var bbooleancube = scene.getMeshByID("booleancube");
    if (scene.getMeshByID("csg")) {
      scene.getMeshByID("csg").dispose();
    }
    if (scene.getMeshByID("csgb")) {
      scene.getMeshByID("csgb").dispose();
    }

    if (SinkConfig.singleSink) {
      let mat = bBecken.material;
      var csga = BABYLON.CSG.FromMesh(bBecken);
      var csgb = BABYLON.CSG.FromMesh(bbooleancube);
      var subcsg = csga.subtract(csgb);
      newSink = subcsg.toMesh("csg", mat, scene);
      newSink.receiveShadows = true;
      newSink.castShadows = true;
      newSink.setEnabled(true);
      bBecken.isVisible = false;
    }else{
      let mat = bBecken.material;
      var csga = BABYLON.CSG.FromMesh(bBecken);
      var csgb = BABYLON.CSG.FromMesh(bbooleancubeLeft);
      var csgc = BABYLON.CSG.FromMesh(bbooleancubeRight)
      var subcsg = csga.subtract(csgb);
      var subcsgb = subcsg.subtract(csgc);
      newSink = subcsgb.toMesh("csg", mat, scene);
      newSink.receiveShadows = true;
      newSink.castShadows = true;
      newSink.setEnabled(true);
      bBecken.isVisible = false;
    }
  }

  /**
   * Will run on every frame render.  
   */
  const onRender = (scene) => {
    if (SCENE.alwaysUpdate) {
      updateScene(scene);
    }
    
  };

  return (
    <>
      <SceneComponent
        antialias
        onSceneReady={onSceneReady}
        onRender={onRender}
        adaptToDeviceRatio
        id="3D-canvas"
      />
    </>
  );
};
export default Canvas;
