import {useState} from "react";
import "semantic-ui-css/semantic.min.css";
import Viewswitch from "./components/Viewswitch";
import Canvas from "./components/Canvas.jsx";
import Ui from './components/Ui'
import Pricetag from './components/Pricetag'
import SinkConfig from './components/SinkConfig'
import './components/Scene'
import './App.css';

function App() {
  const [endprice,setEndprice] = useState(0)
  const [screenshot, setScreenshot] = useState("")
  return (
    <div className="App">
      
      <br />
      
      {/* Main View Grid */}
      <div className="ui stackable centered grid top aligned">
        {/* UI Window */}
        <div className="six wide column Viewcontainer">
          <Ui SinkConfig = {SinkConfig} screenshot={screenshot} setScreenshot={setScreenshot}/>
        </div>
        {/* Preview Window */}
        <div className="nine wide column Viewcontainer">
          {/* View Header */}
          <div className="ui green ribbon label">Vorschau</div>
          {/* VewSwitch to toggle Theme */}
          <Viewswitch
            id="sw_view"
            text="Ansicht umschalten"
            class="ui icon tv"
          />
          <hr className="ui divider" />
          {/* 3D Canvas */}
          <Canvas endprice={endprice} setEndprice={setEndprice} setScreenshot={setScreenshot}/>
          <Pricetag endprice={endprice}/>
        </div>
        
      </div>
      
      
    </div>
  );
}



export default App;
