var SCENE = {
    update: false,
    viewType: false,
    showUnderTable: false,
    showTableWidthLines: false,
    showSinkWidthLines: false,
    showSinkPositionLines: false,
    alwaysUpdate: false,
    makeScreenshot: false,
}
export {SCENE};