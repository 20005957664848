import React from "react";
import { useRef, useState } from "react";
import { Tab, Form, Popup, Image, Card, Rating, Select } from "semantic-ui-react";
import SinkConfig from "./SinkConfig";
import { useEffect } from "react";
import { SCENE } from "./Scene";
import Config from "../Config";
import Preview from "./Preview";
import { configure } from "@testing-library/react";
const Tabui = (props) => {
  //Reference for SinkPosition Input
  const refInpSinkPos = useRef(null);
  //Reference for Label of the SinkPosition
  const refLblSinkPos = useRef("0-0 mm");
  const reflblSinkWidth = useRef("0-0 mm");
  const refInpTableWidth = useRef(null);
  const refLblTableWidth = useRef(null);
  const refSelectSinkWidth = useRef(null);
  const [faucetState, setFaucetValue] = useState(1);
  const [singleTypeState, setSingleType] = useState(1);
  const [sinkTypeState, setSinkType] = useState(0);
  const [materials, setmaterials] = useState([]);
  const [activeMaterial, setActiveMaterial] = useState(SinkConfig.materialid)
  const [options, setOptions] = useState([])
  const [underTable, setUnderTable] = useState(false);
  var optioninner = ""
  async function getOpenOrders() {
    await fetch(Config.ServerURL + "/api/materials", { mode: "cors" })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Cant get material list");
        }
        return response.json();
      })
      .then(function (data) {
        console.log("Received:");
        console.log(data);
        setmaterials(data);
      });
  }

  useEffect(() => {
    console.log("initing");
    getOpenOrders();
    CheckSinkWidthOptions()
  }, []);

  
  function handleFaucetChange(val) {
    setFaucetValue(val);
    SinkConfig.sinkMontage = val;
    //console.log("Wasserhahntyp: " + val);
    SCENE.update = true;
  }

  function handleSingleTypeChange(val) {
    setSingleType(val);
    SinkConfig.singleSink = val;
    //console.log("Einzelbecken: " + val);
    CheckUi()
  }

  function handleSinkTypeChange(val) {
    setSinkType(val);
    SinkConfig.sinkType = val;
    //console.log("Abflusstype: " + val);
    SCENE.update = true;
  }

  function handleUnderTableChange(val) {
    setUnderTable(val.target.checked);
    SinkConfig.withUnderTable = val.target.checked;
    console.log("UnderTable: ", val.target.checked);
    SCENE.showUnderTable = val.target.checked;
    SCENE.update = true;
    
  }

  function handleUiUpdate(e){
    if(e.key==="Enter" || e.key==="Tab"){
      CheckUi()
    }
  }

  function handleSelectUpdate(e){
    console.log(e.target.value)
    
    CheckUi()
    
  }

  function handleFocusTableWidth(){
    SCENE.showTableWidthLines = true
    SCENE.showSinkPositionLines= false
    SCENE.showSinkWidthLines = false
    SCENE.update=true
    console.log("showing TableWidthLines")
  }

  function handleFocusSinkWidth(){
    SCENE.showSinkWidthLines = true
    SCENE.showSinkPositionLines= false
    SCENE.showTableWidthLines = false
    SCENE.update=true
    console.log("showing SinkWidthLines")
  }

  function handleFocusSinkPosition(){
    SCENE.showSinkPositionLines=true
    SCENE.showSinkWidthLines = false
    SCENE.showTableWidthLines = false
    SCENE.update=true
    console.log("showing SinkPositionLines")
  } 

  function handleBlurTableWidth(){
    SCENE.showTableWidthLines = false
    SCENE.showSinkPositionLines= false
    SCENE.showSinkWidthLines = false
    SCENE.update=true
    console.log("showing TableWidthLines")
  }

  function handleBlurSinkWidth(){
    SCENE.showSinkWidthLines = false
    SCENE.showSinkPositionLines= false
    SCENE.showTableWidthLines = false
    SCENE.update=true
    console.log("showing SinkWidthLines")
  }

  function handleBlurSinkPosition(){
    SCENE.showSinkPositionLines=false
    SCENE.showSinkWidthLines = false
    SCENE.showTableWidthLines = false
    SCENE.update=true
    console.log("showing SinkPositionLines")
  } 

  function showPreview(img){
    console.log(img)
    props.setImgURL(img)
  }
  
  //Ui Definitions for the "Dimensionen"-Tab
  const tabDimensionen = (
    <div>
      <h4>Waschtischbreite:</h4>

      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="ui right labeled input">
            <input
              type="number"
              placeholder="Waschtischbreite..."
              defaultValue={SinkConfig.tableWidth}
              id="inpTableWidth"
              onKeyDown={handleUiUpdate}
              ref={refInpTableWidth}
              onFocus={handleFocusTableWidth}
              onBlur={handleBlurTableWidth}
            />
            <div className="ui basic label label" id="lblTableWidth" ref={refLblTableWidth}>
              {SinkConfig.minTableWidth}-{SinkConfig.maxTableWidth} mm
            </div>
          </div>
        </div>
        <div className="six wide column">
          <img
            src="wbreite.png"
            alt=""
            style={{ marginLeft: "40px" }}
            className="ui right floated image descimage"
          />
        </div>
      </div>
      <h4>BeckenBreite:</h4>
      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="ui right labeled input">
          <select
            
            label=''
            options={options}
            placeholder={SinkConfig.sinkWidth}
            ref={refSelectSinkWidth}
            onChange={handleSelectUpdate}
            className="ui selection dropdown"
            onFocus={handleFocusSinkWidth}
            onBlur={handleBlurSinkWidth}
          >
          <option value="400">400 mm</option>
          <option value="600" selected="selected">600 mm</option>
          </select>
            
            <div
              className="ui basic label label"
              id="lblTableWidth"
              ref={reflblSinkWidth}
            >
              450-800 mm
            </div>
            
          </div>
          <br />
          {/* TODO: Grid Layout for images */}
          
        </div>

        <div className="six wide column">
          <img
            src="bbreite.png"
            alt=""
            style={{
              marginLeft: "40px",
            }}
            className="ui right floated image descimage"
          />
        </div>
      </div>
      <h4>Beckenposition:</h4>
      <div className="ui stackable grid">
        <div className="eight wide column">
          <div className="ui right labeled input">
            <input
              type="number"
              placeholder="Enter weight..."
              defaultValue={SinkConfig.sinkPos}
              id="inpSinkPos"
              onKeyDown={handleUiUpdate}
              ref={refInpSinkPos}
              onFocus={handleFocusSinkPosition}
              onBlur={handleBlurSinkPosition}
            />
            <div
              className="ui basic label label"
              id="lblTableWidth"
              ref={refLblSinkPos}
            >
              400-800 mm
            </div>
          </div>
        </div>
        <div className="six wide column">
          <img
            src="bpos.png"
            alt=""
            style={{
              verticalAlign: "middle",
              paddingLeft: "5px",
              marginTop: "5px",
            }}
            className="ui right floated image descimage"
          />
        </div>
      </div>
      
    </div>
  );

  //Ui Definitions for the "Ausstattung"-Tab
  const tabAusstattung = (
    <div>
      <h4>Wasserhahn:</h4>
      <div className="ui form">
        <Form.Group inline>
          <Form.Radio
            label="Beckenmontage"
            value="1"
            checked={faucetState === 1}
            onChange={() => handleFaucetChange(1)}
          />

          <Form.Radio
            label="Wandmontage"
            value="0"
            checked={faucetState === 0}
            onChange={() => handleFaucetChange(0)}
          />
        </Form.Group>
      </div>
      <h4>Waschbeckentyp:</h4>
      <div className="ui form">
        <Form.Group inline>
          <Form.Radio
            label="Einzelbecken"
            value="1"
            checked={singleTypeState === 1}
            onChange={() => handleSingleTypeChange(1)}
          />

          <Form.Radio
            label="Doppelbecken"
            value="0"
            checked={singleTypeState === 0}
            onChange={() => handleSingleTypeChange(0)}
          />
        </Form.Group>
      </div>
      <h4>Abflusstyp:</h4>
      <div className="ui form">
        <Form.Group inline>
          <Form.Radio
            label="Punkt"
            value="0"
            checked={sinkTypeState === 0}
            onChange={() => handleSinkTypeChange(0)}
          />

          <Form.Radio
            label="Rinne"
            value="1"
            checked={sinkTypeState === 1}
            onChange={() => handleSinkTypeChange(1)}
          />

          <Form.Radio
            label="Schlitz"
            value="2"
            checked={sinkTypeState === 2}
            onChange={() => handleSinkTypeChange(2)}
          />
        </Form.Group>
        
      </div>
      
      <h4>Untertischablage:</h4>
      <div className="ui form">
        <div className="ui toggle checkbox">
          <input
            type="checkbox"
            name="a"
            id="a"
            value={underTable}
            onChange={handleUnderTableChange}
          />
          <label>
            <i className="b"></i> Wünschen Sie zu ihrem neuen Waschtisch gleich
            die passende Untertischablage?
          </label>
        </div>
      </div>
    </div>
  );

  const tabMaterials = (
    <div style={{ maxHeight: "50vh", overflowY: "auto" }}>
    
      <table className="ui celled table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Glänzend</th>
            <th>Vorschau</th>
          </tr>
        </thead>
        <tbody>
          {materials.map((material) => (
              material.id === activeMaterial ?
                <tr className="active" key={material.id}>
                <td><a href="#" onClick={() => changeMaterial(material.id, material.image, material.name, material.glossy, material.img_thumb_small)}>{material.name}</a></td>
              <td>
                {material.glossy ? (
                  <b>Ja</b>
                ) : (
                  <b>Nein</b>
                )}
              </td>
              <td>
              <Popup trigger={
                    <a href="#" onClick={() => changeMaterial(material.id, material.image, material.name, material.glossy, material.img_thumb_small)}>
                    <img src={material.img_thumb_small} alt={material.name} ></img>
                    </a>
                    }
                    position='right center'>
                    
                    <Popup.Content>
                    <img src={material.image} alt={material.name} style={{height: "80vh"}}></img>
                    </Popup.Content>
                  </Popup>
              </td>
            </tr>
              :
              <tr key={material.id}>
              <td><a href="#" onClick={() => changeMaterial(material.id, material.image, material.name, material.glossy, material.img_thumb_small)}>{material.name}</a></td>
              <td>
                {material.glossy ? (
                  <b>Ja</b>
                ) : (
                  <b>Nein</b>
                )}
              </td>
              <td>
                
                  <Popup trigger={
                    <a href="#" onClick={() => changeMaterial(material.id, material.image, material.name, material.glossy, material.img_thumb_small)}>
                    <img src={material.img_thumb_small} alt={material.name} ></img>
                    </a>
                    }
                    position='right center'>
                    
                    <Popup.Content>
                    <img src={material.image} alt={material.name} style={{height: "80vh"}}></img>
                    </Popup.Content>
                  </Popup>
                
              </td>
            </tr>
            
          ))}
        </tbody>
      </table>
    </div>
  );

  //Change Material
  function changeMaterial(id, tex, name, gloss, thumb) {
    SinkConfig.materialid = id;
    SinkConfig.materialimage = tex;
    SinkConfig.materialname = name;
    SinkConfig.materialglossy = gloss;
    SinkConfig.thumbnail = thumb
    console.log("thumb")
    console.log(thumb)
    setActiveMaterial(id)
    
    SCENE.update = true;
    
  }
  //Check the given SinkPosition for Validity
  

  //Check the Value Range of the sinkWidth Options
  function CheckSinkWidthOptions(){
    refSelectSinkWidth.current.value=SinkConfig.sinkWidth
    console.log("Checking Sinkstep Options")
    let opt = []
    //Check SinkSize
    let maxSinkWidth =0
    if(SinkConfig.singleSink){
       maxSinkWidth = SinkConfig.tableWidth - SinkConfig.outerBorder*2
    }else{
      maxSinkWidth = SinkConfig.tableWidth/2 - SinkConfig.innerBorder
    }
    if(refSelectSinkWidth.current.value > maxSinkWidth){
      let ret = 0
      for(let i = 0; i<SinkConfig.sinkSteps.length; i++){
        if(SinkConfig.sinkSteps[i]<= maxSinkWidth){
          ret=i
        }
      }
      refSelectSinkWidth.current.value=ret
      SinkConfig.sinkWidth=ret
    }
    if(refSelectSinkWidth.current.value < SinkConfig.minSinkWidth){
      refSelectSinkWidth.current.value=SinkConfig.sinkSteps[0]
      SinkConfig.sinkWidth=SinkConfig.sinkSteps[0]
    }
    console.log("max Sinkwidth: " + maxSinkWidth)
    
    console.log("filling options list")
    for(let i=0; i<SinkConfig.sinkSteps.length; i++){
      console.log("run " + i)
      if(SinkConfig.sinkSteps[i] <= maxSinkWidth){
        console.log("Option: " + maxSinkWidth + " / " + SinkConfig.sinkSteps[i] + " found")
        //opt.push({ key: SinkConfig.sinkSteps[i], text: SinkConfig.sinkSteps[i] + ' mm', value: SinkConfig.sinkSteps[i] })
        if(SinkConfig.sinkSteps[i]!=SinkConfig.sinkWidth){
          console.log("inactive...." + SinkConfig.sinkSteps[i] + "/" + SinkConfig.sinkWidth)
          optioninner += "<option value='" + SinkConfig.sinkSteps[i] + "'>" + SinkConfig.sinkSteps[i]+" mm</option>"
        }else{
          
          console.log("Active.....")
          optioninner += "<option value='" + SinkConfig.sinkSteps[i] + "' selected='selected'>" + SinkConfig.sinkSteps[i]+" mm</option>"
        }
        
        
        
      }else{
        console.log("Option: " + maxSinkWidth + " / " + SinkConfig.sinkSteps[i] + " not found")
      }
    }
    
    refSelectSinkWidth.current.innerHTML = optioninner
    refSelectSinkWidth.current.value=SinkConfig.sinkWidth
    
    setOptions(opt)
  }

  
  //Test for new Checkui
  function CheckUi(){
    console.log("Checking UI consistency")
    let maxSinkPosition = 0
    var minTableWidth = SinkConfig.minTableWidth
    //Check Tablewidth
    SinkConfig.tableWidth = refInpTableWidth.current.value
    
    if(SinkConfig.singleSink){
      minTableWidth = SinkConfig.minTableWidth
    }else {
      minTableWidth = SinkConfig.minTableWidth + SinkConfig.innerBorder + SinkConfig.minSinkWidth + SinkConfig.outerBorder
    }
    
    if(refInpTableWidth.current.value > SinkConfig.maxTableWidth){
      refInpTableWidth.current.value = SinkConfig.maxTableWidth
      SinkConfig.tableWidth = refInpTableWidth.current.value
    }
    if(refInpTableWidth.current.value < minTableWidth){
      refInpTableWidth.current.value = minTableWidth
      SinkConfig.tableWidth = refInpTableWidth.current.value
      
    }
    
    
    //Check SinkSize
    
     var maxSinkWidth =0
    if(SinkConfig.singleSink){
       maxSinkWidth = SinkConfig.tableWidth - SinkConfig.outerBorder*2
    }else{
      maxSinkWidth = SinkConfig.tableWidth/2 - SinkConfig.outerBorder - SinkConfig.innerBorder
    }
    SinkConfig.sinkWidth = refSelectSinkWidth.current.value 
    
    CheckSinkWidthOptions()
    

    //Check Sink Position
    
    if (SinkConfig.singleSink){
      maxSinkPosition = SinkConfig.tableWidth - refSelectSinkWidth.current.value/2 - SinkConfig.outerBorder
      console.log("checkSinkoption: " + (refSelectSinkWidth.current.value/2))
      console.log("checkSinkPosition: " + maxSinkPosition)
    }else {
      maxSinkPosition = SinkConfig.tableWidth/2 - refSelectSinkWidth.current.value/2 - SinkConfig.outerBorder*2 + SinkConfig.outerBorder
      console.log("checkSinkoption: " + (refSelectSinkWidth.current.value/2))
      console.log("checkSinkPosition: " + maxSinkPosition)
    }
    
    SinkConfig.sinkPos = refInpSinkPos.current.value 
    if(refInpSinkPos.current.value > maxSinkPosition){
      refInpSinkPos.current.value = maxSinkPosition
      SinkConfig.sinkPos = refInpSinkPos.current.value
    }
    if(refInpSinkPos.current.value < (SinkConfig.sinkWidth/2 + SinkConfig.outerBorder)){
      refInpSinkPos.current.value = SinkConfig.sinkWidth/2 + SinkConfig.outerBorder
      SinkConfig.sinkPos = refInpSinkPos.current.value
    }

    reflblSinkWidth.current.innerHTML = (SinkConfig.minSinkWidth) + "-" + maxSinkWidth + " mm"
    refLblSinkPos.current.innerHTML = (SinkConfig.sinkWidth/2 + SinkConfig.outerBorder) + "-" + maxSinkPosition + " mm"

    
    SCENE.update = true
  }


  
  const panes = [
    {
      menuItem: "Dimensionen",
      pane: <Tab.Pane className="config">{tabDimensionen}</Tab.Pane>,
    },
    {
      menuItem: "Ausstattung",
      pane: <Tab.Pane className="config">{tabAusstattung}</Tab.Pane>,
    },
    { menuItem: "Material", pane:<Tab.Pane>{tabMaterials}</Tab.Pane> },
  ];
  return <Tab panes={panes} renderActiveOnly={false} />;
};

export default Tabui;
