const Config =  {
    AppName: "Waschtisch-Konfigurator",
    AppVersion: "v4.0",
    MwSt : "19%",
    Copyright: '(c) 2021 by Marco Gaib - Grandedesigns.de',
    MwStNum: 19,
    //ServerURL: 'http://localhost:8000',
    ServerURL: 'http://omnicore.grandedesigns.de'
    //ServerURL: 'http://omnicore.grandedesigns.de:8000'
}

export default Config
