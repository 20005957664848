import React from 'react'
import Config from "../Config"

const Pricetag = (props) => {
    
    return (
        <div className="ui label large orange">
            
        </div>
    )
}

export default Pricetag
